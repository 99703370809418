.CompanyCategoriesList ul {
  margin-top: 30px;
}

.CompanyCategoriesList ul li {
  display: flex;
  cursor: pointer;
  padding: 10px 5px;
}

.CompanyCategoriesList ul li:hover {
  background: rgba(0, 0, 0, .05);
}

.CompanyCategoriesList ul li span {
  flex: 1;
  vertical-align: middle;
}

.CompanyCategoriesList ul li button{
  font-size: 12px;
}
