.Nav {
	width: 200px;
	border-right: 1px solid lightgray;
}

.Nav span {
	display: block;
	cursor: pointer;
	padding: 20px 0;
	text-indent: 30px;
	border-bottom: 1px solid rgba(0, 0, 0, .1);
	color: #444;
	font-size: 14px;
	font-weight: 700;
}

.Nav span.active {
	background: rgba(0, 0, 0, .05);
}
