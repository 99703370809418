.UsersList ul {
  margin-top: 30px;
}

.UsersList ul li {
  display: flex;
  padding: 10px 5px;
}

.UsersList ul li span {
  flex: 1;
  vertical-align: middle;
  white-space: nowrap;
}

.UsersList tr:nth-child(odd) td {
  padding-top: 15px;
}

.UsersList tr:nth-child(even) td {
  background: rgba(0, 0, 0, .05);
  padding: 5px;
}

.UsersList nav {
  float: right;
}

.UsersList dl {
  font-size: 12px;
}

.UsersList dt {
  font-weight: bolder;
}

.UsersList dd {
  padding-left: 20px;
}
