.App {
	position: absolute;
	display: flex;
	width: 100%;
	min-height: 100%;
}

.App .content {
	flex: 1;
	padding: 40px;
}

.App .content h1 {
	font-weight: 900;
	font-size: 34px;
	margin-bottom: 30px;
}
