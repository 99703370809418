html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
  width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

thead {
  font-weight: bolder;
}

td {
  padding: 5px 0;
}

body {
	font-family: 'Roboto', sans-serif;
}

* {
    box-sizing: border-box;
}

button {
	font-size: 13px;
}

.Login form {
	width: 200;
	height: 200;
	top: 50%;
	left: 50%;
	text-align: center;
	line-height: 30px;
	position: absolute;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.Login label {
	display: block;
}

.Nav {
	width: 200px;
	border-right: 1px solid lightgray;
}

.Nav span {
	display: block;
	cursor: pointer;
	padding: 20px 0;
	text-indent: 30px;
	border-bottom: 1px solid rgba(0, 0, 0, .1);
	color: #444;
	font-size: 14px;
	font-weight: 700;
}

.Nav span.active {
	background: rgba(0, 0, 0, .05);
}

.ArticlesList ul {
	margin-top: 30px;
}

.ArticlesList ul li {
	display: flex;
	cursor: pointer;
	padding: 10px 5px;
}

.ArticlesList ul li.not-published {
  background: rgba(0, 0, 0, .03);
}

.ArticlesList ul li:hover {
	background: rgba(0, 0, 0, .05);
}

.ArticlesList ul li span {
	flex: 1 1;
	vertical-align: middle;
}

.ArticlesList ul li span.title {
	flex: 6 1;
}

.ArticlesList ul li span.since {
	white-space: nowrap;
}

.ArticlesList ul li button{
	font-size: 12px;
}

.Form {
  margin-top: 30px;
}

.Form label {
  display: block;
  margin-top: 25px;
}

.Form label > span,
.Form label > details > summary > span {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  color: gray;
  font-weight: 700;
}

.Form label > details > summary > span {
  display: inline;
}

.Form label p {
  color: #444;
  font-size: 12px;
  padding: 5px 0;
}

.Form input,
.Form textarea {
  border: none;
  border-bottom: 1px solid lightgray;
  color: #444;
  font-size: 16px;
  width: 100%;
  padding: 8px 0;
}

.Form textarea {
  resize: vertical;
  height: 300px;
  line-height: 1.5;
}

.Form textarea.accepts {
  border: 1px solid lightgreen;
}

.Form img {
  max-width: 500px;
  max-height: 120px;
  border: 10px solid lightgray;
  box-shadow: 0 0 1px gray;
  margin-top: 10px;
}

.Form input[type="submit"] {
  width: auto;
  font-weight: 900;
  border: 1px solid lightgray;
  background: rgba(0, 0, 0, 0.05);
  margin-top: 15px;
  padding: 8px 30px;
  border-radius: 2px;
}

.Form input[type="submit"][disabled] {
  opacity: 0.4;
}

.Form .legend {
  padding: 15px 30px;
  margin: 10px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.Form .legend b {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

.Form .legend ul {
  font-size: 14px;
  list-style: circle;
}

.Form .legend ul li {
  padding: 6px 0;
}

.Form .legend ul code {
  font-family: monospace;
  display: block;
  margin-bottom: 4px;
}

.Form .legend ul label {
  display: inline-block;
  margin: 0;
}

.contest-answer {
  padding-left: 50px;
}

.cotest-actions-column {
  text-align: center;
}

.ArticleForm .result.fail {
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: red;
  margin-top: 10px;
}

.ArticleParticipants header {
  padding-top: 20px;
}

.ArticleParticipants header label {
  font-weight: bolder;
  font-size: 12px;
  margin-right: 15px;
}

.ArticleParticipants header label select {
  margin-left: 5px;
}

.ArticleParticipants table {
  margin-top: 30px;
}

.ArticleParticipants table td {
  padding: 10px 5px;
}

.ArticleParticipants table tr:nth-child(even) td {
  background: rgba(0, 0, 0, .05);
}

.ArticleParticipants .answer {
  display: inline-block;
  color: white;
  text-align: center;
  line-height: 30px;
  width: 30px;
  height: 30px;
}

.ArticleParticipants .proper-answer {
  background-color: lightgreen;
}

.ArticleParticipants .invalid-answer {
  background-color: darkred;
}
.CompaniesList ul.categories {
  margin-top: 30px;
}

.CompaniesList .category {
  display: block;
  padding: 5px;
  margin-top: 5px;
  font-weight: bold;
}

.CompaniesList ul.companies {
  margin-left: 15px;
}

.CompaniesList ul.companies li {
  display: flex;
  cursor: pointer;
  padding: 10px 5px;
}

.CompaniesList ul.companies li:not(.head):hover {
  background: rgba(0, 0, 0, 0.05);
}

.CompaniesList ul.companies li.head {
  cursor: auto;
  font-weight: bolder;
}

.CompaniesList ul.companies li span {
  flex: 1 1;
  vertical-align: middle;
}

.CompaniesList ul.companies li span.title {
  flex: 6 1;
}

.CompaniesList ul.companies li span.since {
  white-space: nowrap;
}

.CompaniesList ul.companies li .actions {
  width: 180px;
}

.CompaniesList ul.companies li button {
  font-size: 12px;
}

.CompanyProducts h3 {
  font-size: 30px;
  font-weight: 900;
  margin-top: 40px;
  color: #444;
}

.CompanyProducts .products,
.CompanyProducts .pendingProducts {
  display: block;
}

.CompanyProducts .pendingProducts {
  padding-top: 15px;
}

.CompanyProducts .products > li,
.CompanyProducts .pendingProducts > li {
  display: inline-block;
  vertical-align: top;
  margin: 0 20px 20px 0;
}

.CompanyProducts .products > li img {
  width: 200px;
}

.CompanyProducts .pendingProducts > li {
  text-align: center;
}

.CompanyProducts .pendingProducts p {
  font-size: 13px;
  font-weight: bold;
}

.CompanyProducts .pendingProducts p.url {
  padding-bottom: 10px;
}

.CompanyProducts .pendingProducts p.error {
  padding-top: 10px;
  color: red;
}

.CompanyProducts .product {
  width: 220px;
  box-shadow: 2px 2px 10px #ccc;
  padding: 10px;
}

.CompanyProducts .product .Form,
.CompanyProducts .product label:first-child {
  margin-top: 0;
}

.CompanyProducts .product input,
.CompanyProducts .product select {
  width: 100%;
}

.CompanyProducts .product button {
  margin-top: 10px;
  width: 100%;
}

.CompanyPdfs h3 {
  font-size: 30px;
  font-weight: 900;
  margin-top: 40px;
  color: #444;
}

.CompanyPdfs .pdfs,
.CompanyPdfs .pendingPdfs {
  display: block;
}

.CompanyPdfs .pendingPdfs {
  padding-top: 15px;
}

.CompanyPdfs .pdfs > li,
.CompanyPdfs .pendingPdfs > li {
  display: inline-block;
  vertical-align: top;
  margin: 0 20px 20px 0;
}

.CompanyPdfs .pdfs > li img {
  width: 200px;
}

.CompanyPdfs .pendingPdfs > li {
  text-align: center;
}

.CompanyPdfs .pendingPdfs p {
  font-size: 13px;
  font-weight: bold;
}

.CompanyPdfs .pendingPdfs p.error {
  padding-top: 10px;
  color: red;
}

.CompanyPdfs .pdf {
  width: 220px;
  box-shadow: 2px 2px 10px #ccc;
  padding: 10px;
}

.CompanyPdfs .pdf .Form,
.CompanyPdfs .pdf label:first-child {
  margin-top: 0;
}

.CompanyPdfs .pdf input,
.CompanyPdfs .pdf select {
  width: 100%;
}

.CompanyPdfs .pdf button {
  margin-top: 10px;
  width: 100%;
}

.CompanyCategoriesList ul {
  margin-top: 30px;
}

.CompanyCategoriesList ul li {
  display: flex;
  cursor: pointer;
  padding: 10px 5px;
}

.CompanyCategoriesList ul li:hover {
  background: rgba(0, 0, 0, .05);
}

.CompanyCategoriesList ul li span {
  flex: 1 1;
  vertical-align: middle;
}

.CompanyCategoriesList ul li button{
  font-size: 12px;
}

.ProductCategoriesList > ul {
  margin-top: 30px;
}

.ProductCategoriesList ul ul {
  margin-left: 15px;
}

.ProductCategoriesList main {
  display: flex;
  cursor: pointer;
  padding: 10px 5px;
}

.ProductCategoriesList ul main:hover {
  background: rgba(0, 0, 0, .05);
}

.ProductCategoriesList span.title {
  flex: 6 1;
}

.ProductCategoriesList ul li span {
  flex: 1 1;
  vertical-align: middle;
}

.ProductCategoriesList ul li button{
  font-size: 12px;
}


.MessagePage .result {
  font-size: 18px;
  font-weight: 600;
}

.MessagePage .result.ok {
  color: green;
}

.MessagePage .result.fail {
  color: red;
}

.UsersList ul {
  margin-top: 30px;
}

.UsersList ul li {
  display: flex;
  padding: 10px 5px;
}

.UsersList ul li span {
  flex: 1 1;
  vertical-align: middle;
  white-space: nowrap;
}

.UsersList tr:nth-child(odd) td {
  padding-top: 15px;
}

.UsersList tr:nth-child(even) td {
  background: rgba(0, 0, 0, .05);
  padding: 5px;
}

.UsersList nav {
  float: right;
}

.UsersList dl {
  font-size: 12px;
}

.UsersList dt {
  font-weight: bolder;
}

.UsersList dd {
  padding-left: 20px;
}

.PagesList ol {
  list-style: decimal;
  padding-bottom: 5px;
}

.PagesList li {
  padding: 5px 0;
}

.PagesList select {
  width: 300px;
  margin-right: 5px;
}

.PagesList button {
  margin: 0 5px;
}

.PagesList .footer {
  padding-bottom: 50px;
}

.App {
	position: absolute;
	display: flex;
	width: 100%;
	min-height: 100%;
}

.App .content {
	flex: 1 1;
	padding: 40px;
}

.App .content h1 {
	font-weight: 900;
	font-size: 34px;
	margin-bottom: 30px;
}

