.ProductCategoriesList > ul {
  margin-top: 30px;
}

.ProductCategoriesList ul ul {
  margin-left: 15px;
}

.ProductCategoriesList main {
  display: flex;
  cursor: pointer;
  padding: 10px 5px;
}

.ProductCategoriesList ul main:hover {
  background: rgba(0, 0, 0, .05);
}

.ProductCategoriesList span.title {
  flex: 6 1;
}

.ProductCategoriesList ul li span {
  flex: 1;
  vertical-align: middle;
}

.ProductCategoriesList ul li button{
  font-size: 12px;
}

