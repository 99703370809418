.PagesList ol {
  list-style: decimal;
  padding-bottom: 5px;
}

.PagesList li {
  padding: 5px 0;
}

.PagesList select {
  width: 300px;
  margin-right: 5px;
}

.PagesList button {
  margin: 0 5px;
}

.PagesList .footer {
  padding-bottom: 50px;
}
