.ArticleParticipants header {
  padding-top: 20px;
}

.ArticleParticipants header label {
  font-weight: bolder;
  font-size: 12px;
  margin-right: 15px;
}

.ArticleParticipants header label select {
  margin-left: 5px;
}

.ArticleParticipants table {
  margin-top: 30px;
}

.ArticleParticipants table td {
  padding: 10px 5px;
}

.ArticleParticipants table tr:nth-child(even) td {
  background: rgba(0, 0, 0, .05);
}

.ArticleParticipants .answer {
  display: inline-block;
  color: white;
  text-align: center;
  line-height: 30px;
  width: 30px;
  height: 30px;
}

.ArticleParticipants .proper-answer {
  background-color: lightgreen;
}

.ArticleParticipants .invalid-answer {
  background-color: darkred;
}