.Login form {
	width: 200;
	height: 200;
	top: 50%;
	left: 50%;
	text-align: center;
	line-height: 30px;
	position: absolute;
	transform: translate(-50%, -50%);
}

.Login label {
	display: block;
}
