.Form {
  margin-top: 30px;
}

.Form label {
  display: block;
  margin-top: 25px;
}

.Form label > span,
.Form label > details > summary > span {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  color: gray;
  font-weight: 700;
}

.Form label > details > summary > span {
  display: inline;
}

.Form label p {
  color: #444;
  font-size: 12px;
  padding: 5px 0;
}

.Form input,
.Form textarea {
  border: none;
  border-bottom: 1px solid lightgray;
  color: #444;
  font-size: 16px;
  width: 100%;
  padding: 8px 0;
}

.Form textarea {
  resize: vertical;
  height: 300px;
  line-height: 1.5;
}

.Form textarea.accepts {
  border: 1px solid lightgreen;
}

.Form img {
  max-width: 500px;
  max-height: 120px;
  border: 10px solid lightgray;
  box-shadow: 0 0 1px gray;
  margin-top: 10px;
}

.Form input[type="submit"] {
  width: auto;
  font-weight: 900;
  border: 1px solid lightgray;
  background: rgba(0, 0, 0, 0.05);
  margin-top: 15px;
  padding: 8px 30px;
  border-radius: 2px;
}

.Form input[type="submit"][disabled] {
  opacity: 0.4;
}

.Form .legend {
  padding: 15px 30px;
  margin: 10px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.Form .legend b {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

.Form .legend ul {
  font-size: 14px;
  list-style: circle;
}

.Form .legend ul li {
  padding: 6px 0;
}

.Form .legend ul code {
  font-family: monospace;
  display: block;
  margin-bottom: 4px;
}

.Form .legend ul label {
  display: inline-block;
  margin: 0;
}
