.CompanyPdfs h3 {
  font-size: 30px;
  font-weight: 900;
  margin-top: 40px;
  color: #444;
}

.CompanyPdfs .pdfs,
.CompanyPdfs .pendingPdfs {
  display: block;
}

.CompanyPdfs .pendingPdfs {
  padding-top: 15px;
}

.CompanyPdfs .pdfs > li,
.CompanyPdfs .pendingPdfs > li {
  display: inline-block;
  vertical-align: top;
  margin: 0 20px 20px 0;
}

.CompanyPdfs .pdfs > li img {
  width: 200px;
}

.CompanyPdfs .pendingPdfs > li {
  text-align: center;
}

.CompanyPdfs .pendingPdfs p {
  font-size: 13px;
  font-weight: bold;
}

.CompanyPdfs .pendingPdfs p.error {
  padding-top: 10px;
  color: red;
}

.CompanyPdfs .pdf {
  width: 220px;
  box-shadow: 2px 2px 10px #ccc;
  padding: 10px;
}

.CompanyPdfs .pdf .Form,
.CompanyPdfs .pdf label:first-child {
  margin-top: 0;
}

.CompanyPdfs .pdf input,
.CompanyPdfs .pdf select {
  width: 100%;
}

.CompanyPdfs .pdf button {
  margin-top: 10px;
  width: 100%;
}
