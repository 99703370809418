.CompaniesList ul.categories {
  margin-top: 30px;
}

.CompaniesList .category {
  display: block;
  padding: 5px;
  margin-top: 5px;
  font-weight: bold;
}

.CompaniesList ul.companies {
  margin-left: 15px;
}

.CompaniesList ul.companies li {
  display: flex;
  cursor: pointer;
  padding: 10px 5px;
}

.CompaniesList ul.companies li:not(.head):hover {
  background: rgba(0, 0, 0, 0.05);
}

.CompaniesList ul.companies li.head {
  cursor: auto;
  font-weight: bolder;
}

.CompaniesList ul.companies li span {
  flex: 1;
  vertical-align: middle;
}

.CompaniesList ul.companies li span.title {
  flex: 6;
}

.CompaniesList ul.companies li span.since {
  white-space: nowrap;
}

.CompaniesList ul.companies li .actions {
  width: 180px;
}

.CompaniesList ul.companies li button {
  font-size: 12px;
}
