.ArticlesList ul {
	margin-top: 30px;
}

.ArticlesList ul li {
	display: flex;
	cursor: pointer;
	padding: 10px 5px;
}

.ArticlesList ul li.not-published {
  background: rgba(0, 0, 0, .03);
}

.ArticlesList ul li:hover {
	background: rgba(0, 0, 0, .05);
}

.ArticlesList ul li span {
	flex: 1;
	vertical-align: middle;
}

.ArticlesList ul li span.title {
	flex: 6;
}

.ArticlesList ul li span.since {
	white-space: nowrap;
}

.ArticlesList ul li button{
	font-size: 12px;
}
