.MessagePage .result {
  font-size: 18px;
  font-weight: 600;
}

.MessagePage .result.ok {
  color: green;
}

.MessagePage .result.fail {
  color: red;
}
