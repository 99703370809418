.CompanyProducts h3 {
  font-size: 30px;
  font-weight: 900;
  margin-top: 40px;
  color: #444;
}

.CompanyProducts .products,
.CompanyProducts .pendingProducts {
  display: block;
}

.CompanyProducts .pendingProducts {
  padding-top: 15px;
}

.CompanyProducts .products > li,
.CompanyProducts .pendingProducts > li {
  display: inline-block;
  vertical-align: top;
  margin: 0 20px 20px 0;
}

.CompanyProducts .products > li img {
  width: 200px;
}

.CompanyProducts .pendingProducts > li {
  text-align: center;
}

.CompanyProducts .pendingProducts p {
  font-size: 13px;
  font-weight: bold;
}

.CompanyProducts .pendingProducts p.url {
  padding-bottom: 10px;
}

.CompanyProducts .pendingProducts p.error {
  padding-top: 10px;
  color: red;
}

.CompanyProducts .product {
  width: 220px;
  box-shadow: 2px 2px 10px #ccc;
  padding: 10px;
}

.CompanyProducts .product .Form,
.CompanyProducts .product label:first-child {
  margin-top: 0;
}

.CompanyProducts .product input,
.CompanyProducts .product select {
  width: 100%;
}

.CompanyProducts .product button {
  margin-top: 10px;
  width: 100%;
}
